import clsx from "clsx";
import { CopyIcon } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import ReactSelect from "react-select/creatable";
import { Input } from "~/design-system/atom/input";
import { toast } from "~/design-system/atom/use-toast";
import { copyText } from "~/utils/dom";

export const FormFieldRenderer = ({
    type,
    value,
    setValue,
    placeholder,
    isRequired,
    error,
    disabled,
    expected_from_customer,
    description,
    choices,
    label,
    name,
    layout = "vertical",
}: {
    type: string;
    value: string;
    setValue: (value: string) => void;
    placeholder: string | undefined;
    isRequired: boolean;
    error?: string;
    name: string;
    disabled?: boolean;
    expected_from_customer: boolean;
    label?: string;
    description: string;
    choices: {
        value: string;
        label: string;
    }[];
    isExistingConnector: boolean;
    layout: "horizontal" | "vertical";
}) => {
    // Ideally this logic shouldn't be here, but this is an exception field - oauth_redirect_uri
    // TODO: Remove this once we have a better way to handle custom fields
    const isOauthRedirectUriField = name === "oauth_redirect_uri";

    const [isDisabled, setIsDisabled] = useState(!!expected_from_customer || disabled || isOauthRedirectUriField);
    const inputRef = useRef<HTMLInputElement>(null);
    useEffect(() => {
        setIsDisabled(!!expected_from_customer || disabled || isOauthRedirectUriField);
    }, [expected_from_customer, disabled, isOauthRedirectUriField]);

    if (type === "string") {
        const isHorizontal = layout === "horizontal";
        const isEditMode = isOauthRedirectUriField && !isDisabled;
        return (
            <div className={`w-full ${isHorizontal ? "flex w-full items-center justify-between" : ""}`}>
                <div className="w-[fit-content]">
                    <div
                        className={clsx(
                            "mb-[2px] w-[fit-content] text-left font-cera text-[14px]  font-[500] text-black-500",
                            isHorizontal ? "font-cera !text-[15px] !font-[500] leading-none !text-black-400" : "",
                        )}
                    >
                        {label || placeholder} {isEditMode && "- (Custom)"}
                        {isRequired && !expected_from_customer && <span className="ml-1  text-[#ff5b81] ">*</span>}
                    </div>
                    <div
                        className={clsx(
                            "mt-2 text-left text-[12px] tracking-[.3px] text-black-900 flex",
                            isHorizontal ? "max-w-[80%] font-gilroy !text-[13px] !font-[400] !text-black-800" : "!mt-1 mb-3",
                        )}
                        title={description}
                    >
                        <div>{isHorizontal ? description : description.length > 100 ? `${description.slice(0, 100)}...` : description}</div>
                        {isOauthRedirectUriField && isDisabled && (
                            <div
                                className="text-[12px] text-[#ff5b81] ml-4 cursor-pointer hover:opacity-75"
                                onClick={() => {
                                    setIsDisabled(false);
                                    setTimeout(() => {
                                        inputRef.current?.select();
                                    }, 0);
                                }}
                            >
                                Edit
                            </div>
                        )}
                    </div>
                </div>
                <div>
                    <Input
                        className={`max w-[100%] flex-auto gap-1.5 !rounded-[10px] text-[13px] font-[400] text-black-400 ${disabled ? "!bg-[transparent]" : ""} ${isOauthRedirectUriField ? "!pr-8" : ""}`}
                        type="text"
                        placeholder={placeholder}
                        size={isHorizontal ? 32 : 36}
                        value={value}
                        ref={inputRef}
                        onChange={(e) => {
                            if (!!expected_from_customer || disabled || isDisabled) return;
                            setValue(e.target.value);
                        }}
                        rightIcon={
                            isOauthRedirectUriField ? (
                                <CopyIcon
                                    color="#000"
                                    height={14}
                                    width={14}
                                    onClick={() => {
                                        toast({
                                            title: "Redirect URL copied to clipboard",
                                            description: "Please paste this URL in your developer app",
                                            variant: "success",
                                        });
                                        copyText(value, inputRef.current);
                                    }}
                                    className="cursor-pointer"
                                />
                            ) : null
                        }
                    />
                    {expected_from_customer && isRequired && (
                        <div className="ml-1  mt-2 text-[13px] font-[500]  text-[#ba410f]">
                            Field is expected from customer. You do not need to fill this field.
                        </div>
                    )}
                    <div className="ml-1  mt-2 text-[12px]  text-[#ff5b81]">{error}</div>
                </div>
            </div>
        );
    }

    if (type === "select") {
        return (
            <SelectFormField
                placeholder={placeholder}
                value={value}
                disabled={!!expected_from_customer || disabled}
                setValue={setValue}
                choices={choices}
                error={error}
                expected_from_customer={expected_from_customer}
                isRequired={isRequired}
                description={description}
            />
        );
    }
};

const SelectFormField = ({
    value,
    setValue,
    placeholder,
    isRequired,
    error,
    disabled,
    expected_from_customer,
    description,
    choices,
}: {
    value: string;
    setValue: (
        value: string,
        choices: {
            value: string;
            label: string;
        }[],
    ) => void;
    placeholder: string | undefined;
    isRequired: boolean;
    error?: string;
    disabled?: boolean;
    expected_from_customer: boolean;
    description: string;
    choices: {
        value: string;
        label: string;
    }[];
}) => {
    const selectedValues = value.split(",");
    const selectedValuesNotInChoices = selectedValues
        .filter((value) => !choices.map((choice) => choice.value).includes(value))
        .map((value) => ({
            value,
            label: value,
        }));

    const localChoices = [...choices, ...selectedValuesNotInChoices];

    return (
        <div>
            <div className="mb-[2px] text-left text-[13px]  font-[600] text-black-600">
                {placeholder}
                {isRequired && !expected_from_customer && <span className="ml-1  text-[#ff5b81] ">*</span>}
            </div>
            <div className="mb- text-left text-[12px]  text-black-900">{description}</div>
            <ReactSelect
                options={localChoices || []}
                className="react-select-container"
                classNamePrefix="react-select"
                styles={{
                    control: (base) => ({
                        ...base,
                        width: "238px",
                        border: "1px solid #c6c6c6",
                        borderRadius: "8px",
                        "&:hover": {
                            border: "1px solid #c6c6c6",
                        },
                    }),
                    option: (base) => ({
                        ...base,
                        color: "black",
                    }),
                }}
                isClearable={false}
                isDisabled={!!expected_from_customer || !!disabled}
                value={localChoices.filter((choice) =>
                    value
                        .split(",")
                        .filter((value) => !!value)
                        .includes(choice.value),
                )}
                isMulti
                onChange={(e) => {
                    const newValues = e.map((option) => option.value).join(",");

                    // @ts-expect-error this is just for backward compatibility
                    setValue(newValues);
                }}
            />
            {expected_from_customer && isRequired && (
                <div className="ml-1  mt-2 text-[13px] font-[500]  text-[#ba410f]">
                    Field is expected from customer. You do not need to fill this field.
                </div>
            )}
            <div className="ml-1  mt-2 text-[12px]  text-[#ff5b81]">{error}</div>
        </div>
    );
};
